import React from 'react';
import {Button, Col, Row, Tabs, Typography} from 'antd';
import styles from './ServicesDetailed.module.less';
import {useIntl} from 'gatsby-plugin-intl';
import useGlobalState from '../../hooks/useGlobalState';
import useServicesImage from '../../hooks/useServicesImage';
import {Link} from 'gatsby';
import Lottie from 'react-lottie-segments';

const ServicesTabsMobile = () => {
    const {formatMessage, locale} = useIntl();
    const {TabPane} = Tabs;
    const {Title} = Typography;
    const config = [
        'ux-design',
        'ui-design',
        'user-research',
    ];
    const globalState = useGlobalState();
    const activeTab = globalState.activeTab;
    const {getAnimationSrc} = useServicesImage();

    const getArticleUrl = (item) => {
        switch (item) {
            default:
            case 'ux-design':
                return 'did_you_like_the_experience';
            case 'ui-design':
                return 'how_do_I_get_to_the_entrance';
            case 'user-research':
                return 'observe_your_users_perceptions';
        }
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: getAnimationSrc(activeTab),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <Tabs type="card" className="custom show-md-down"
              defaultActiveKey={activeTab}
              onTabClick={(key) => globalState.setActiveTab(key)}
        >
            {config.map((item) => {
                return (
                    <TabPane tab={formatMessage({id: `${item}.tab.mobile`})} key={item}>
                        <Row>
                            <Col xs={{span: 12}}>
                                <Lottie options={defaultOptions} />
                            </Col>
                            <Col xs={{span: 12}}>
                                <Title level={4} className={`${styles.title}`}>
                                    {formatMessage({id: `services.${item}.title`})}
                                </Title>
                                <p className={styles.subtitle}>
                                    {formatMessage({id: `services.${item}.description.part1`})}
                                </p>
                                <p className={styles.paragraph}>
                                    {formatMessage({id: `services.${item}.description.part2`})}
                                </p>
                                <div className="text-center">
                                    <Link
                                        to={`/${locale}/magazine/${getArticleUrl(item)}`}
                                        className="full-width"
                                    >
                                        <Button type="ghost">
                                            {formatMessage({id: 'read-more.button.label'})}
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                )
            })}
        </Tabs>
    );
};

export default ServicesTabsMobile;