import React from 'react';
import styles from './MakeContactBlock.module.less';
import {Button, Typography} from 'antd';
import {Link} from 'gatsby';
import {useIntl} from 'gatsby-plugin-intl';

const MakeContactBlock = () => {
    const {formatMessage, locale} = useIntl();
    const {Text} = Typography;

    return (
        <div className="container">
            <div className={`${styles.subSection} animated-gradient`}>
                <div className={styles.contentWrapper}>
                    <Text strong className={styles.text}>{formatMessage({id: 'make-contact.title-strong'})}</Text>
                    <Text className={styles.text}>{formatMessage({id: 'make-contact.title'})}</Text>
                    <div className={styles.buttonContainer}>
                        <Link to={`/${locale}/contact`}>
                            <Button className={`${styles.button} animated`} ghost>{formatMessage({id: 'make-contact.button'})}</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MakeContactBlock;
