import React from 'react';
import styles from './UserInfoBlock.module.less';
import {Avatar, Button, Col, Row, Typography} from 'antd';
import {useIntl} from 'gatsby-plugin-intl';
import avatarImage from '../../assets/mia-graphic-designer.png';
import {Link} from 'gatsby';

const UserInfoBlock = () => {
    const {Text, Title} = Typography;
    const {formatMessage, locale} = useIntl();

    return (
        <div className="container">
            <div className={styles.avatarContainer}>
                <Avatar size={80} src={avatarImage} className={styles.avatar} alt={formatMessage({id: 'about.avatar.alt-text'})}/>
                <Text strong className={styles.avatarTitle}>{formatMessage({id: 'about.user.title'})}</Text>
                <Text className={styles.avatarSubtitle}>{formatMessage({id: 'about.user.subtitle'})}</Text>
            </div>
            <div className={styles.bio}>
                <Title level={3} className={styles.bioTitle}>{formatMessage({id: 'about.bio.title'})}</Title>
                <Row gutter={30}>
                    <Col xs={{span: 12}} sm={{span: 6}}>
                        <Row>
                            <Col span={12}><p>{formatMessage({id: 'about.bio.part1'})}</p></Col>
                            <Col span={12}><p>{formatMessage({id: 'about.bio.part2'})}</p></Col>
                        </Row>
                    </Col>
                    <Col xs={{span: 12}} sm={{span: 6}}>
                        <Row>
                            <Col span={12}><p>{formatMessage({id: 'about.bio.part3'})}</p></Col>
                            <Col span={12}>
                                <p className={styles.bioHighlight}>{formatMessage({id: 'about.bio.part4'})}</p>
                                <p className={styles.bioHighlight}>{formatMessage({id: 'about.bio.part5'})}</p>
                            </Col>
                            <Col span={12}>
                                <Link to={`/${locale}/magazine/looks_fancy_but_does_it_work`} className={`full-width ${styles.buttonLink}`}>
                                    <Button type="ghost">
                                        {formatMessage({id: 'learn-more.button.label'})}
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default UserInfoBlock;