import React from 'react';
import MakeContactBlock from '../components/make-contact-block/MakeContactBlock';
import UserInfoBlock from '../components/user-info-block/UserInfoBlock';
import StaticQuote from '../components/static-quote/StaticQuote';
import VisibilitySensor from '../components/VisibilitySensor';
import Helmet from 'react-helmet';
import {useIntl} from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import IAMMIAFAQBlock from '../components/iam-mia-faq-block/IAMMIAFAQBlock';
import IAMMIAFAQBlockMobile from '../components/iam-mia-faq-block-mobile/IAMMIAFAQBlockMobile';
import {graphql} from 'gatsby';
import VacanciesTeaser from '../components/vacancies-teaser/VacanciesTeaser';
import {Divider} from 'antd';
import styles from '../templates/vacancy-template/vacancyTemplate.module.less';
import ServicesDetailed from '../components/services-detailed/ServicesDetailed';

const About = ({data}) => {
    const {formatMessage} = useIntl();

    const jobs = data.allContentfulVacancy.edges
        .map(({node: job}) => job);

    return (
        <Layout data={data}>
            <Helmet>
                <title>{formatMessage({id: 'about.page-title'})}</title>
                <meta name="description" content={formatMessage({id: 'about.page-description'})} />
            </Helmet>
            <UserInfoBlock />
            <VisibilitySensor>
                <StaticQuote />
            </VisibilitySensor>
            <VisibilitySensor>
                <ServicesDetailed />
            </VisibilitySensor>
            <VisibilitySensor>
                <Divider className={styles.divider} />
                <div className="show-lg-down">
                    <IAMMIAFAQBlockMobile  />
                </div>
                <div className="show-lg-up">
                    <IAMMIAFAQBlock  />
                </div>
            </VisibilitySensor>
            <VisibilitySensor>
                <Divider className={styles.divider} />
                <VacanciesTeaser jobs={jobs} />
            </VisibilitySensor>
            <VisibilitySensor>
                <MakeContactBlock />
            </VisibilitySensor>
        </Layout>
    );
};

export default About;

export const pageQuery = graphql`
  query($locale: String) {
    allContentfulVacancy(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          id
          title
          slug
          employmentType
          category { 
            color
          }
        }
      }
    },
    allContentfulArticle(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulStory(
      sort: {fields: [category___createdAt, createdAt], order: [ASC, DESC]}, 
      filter: {node_locale: {eq: $locale}, slug: {ne: "skip"}}
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;
