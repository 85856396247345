import React from 'react';
import {Col, Row, Typography} from 'antd';
import {useIntl} from 'gatsby-plugin-intl';
import styles from './StaticQuote.module.less';

const StaticQuote = () => {
    const {Title, Text} = Typography;
    const {formatMessage} = useIntl();

    return (
        <div className={styles.section}>
            <div className="container">
                <Row gutter={{xs: 0, lg: 30}} type="flex" justify="center">
                    <Col xs={{span: 12}} sm={{span: 6}}>
                        <div className={`${styles.border} border`} />
                        <Title level={3} className={`custom-title ${styles.title}`}><span className="word animated">{formatMessage({id: 'about.quote.title.first-word'})}</span> {formatMessage({id: 'about.quote.title.rest'})}</Title>
                    </Col>
                    <Col xs={{span: 12}} sm={{span: 6}}>
                        <Text className={styles.description}>{formatMessage({id: 'about.quote.description'})}</Text>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default StaticQuote;
