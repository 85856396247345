import React from 'react';
import {Button, Collapse, Row, Typography} from 'antd';
import styles from './IAMMIAFAQBlockMobile.module.less';
import {useIntl} from 'gatsby-plugin-intl';
import {Link} from "gatsby";

const IAMMIAFAQBlockMobile = () => {
    const {Title} = Typography;
    const {Panel} = Collapse;
    const {formatMessage, locale} = useIntl();

    return (
        <div className={styles.section}>
            <div className="container">
                <Title level={3} className={styles.title}>
                    {formatMessage({id: 'faq-block.title'})}
                </Title>
                <Collapse accordion bordered={false} className="custom-collapse">
                    {[...Array(5).keys()].map(i => (
                        <Panel
                            header={formatMessage(
                                {id: `faq.tab-${i}.title`},
                                {strong: (...chunks) => <strong>{chunks}</strong>},
                            )}
                            key={i}
                            showArrow={false}
                        >
                            <p className={styles.description}>
                                {formatMessage(
                                    {id: `faq.tab-${i}.description`},
                                    {
                                        strong: (...chunks) => <strong>{chunks}</strong>,
                                        br: <br />,
                                    }
                                )}
                            </p>
                        </Panel>
                    ))}
                </Collapse>
                <Row align="center">
                    <Link to={`/${locale}/contact`} className="full-width">
                        <Button type="ghost">
                            {formatMessage({id: 'faq.button.label'})}
                        </Button>
                    </Link>
                </Row>
            </div>
        </div>
    );
};

export default IAMMIAFAQBlockMobile;
