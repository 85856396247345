import React from 'react';
import {Button, Row, Tabs, Typography} from 'antd';
import styles from './IAMMIAFAQBlock.module.less';
import {useIntl} from 'gatsby-plugin-intl';
import {Link} from 'gatsby';

const IAMMIAFAQBlock = () => {
    const {Title} = Typography;
    const {TabPane} = Tabs;
    const {formatMessage, locale} = useIntl();

    return (
        <div className={styles.section}>
            <div className="container">
                <Title level={3} className={styles.title}>
                    {formatMessage({id: 'faq-block.title'})}
                </Title>
                <Tabs tabPosition="left" className="vertical-tabs ant-tabs-left">
                    {[...Array(5).keys()].map(i => (
                        <TabPane tab={formatMessage(
                            {id: `faq.tab-${i}.title`},
                            {strong: (...chunks) => <strong>{chunks}</strong>},
                        )} key={i}>
                            <p className={styles.description}>
                                {formatMessage(
                                    {id: `faq.tab-${i}.description`},
                                    {
                                        strong: (...chunks) => <strong>{chunks}</strong>,
                                        br: <br />,
                                    }
                                )}
                            </p>
                        </TabPane>
                    ))}
                </Tabs>
                <Row align="center">
                    <Link to={`/${locale}/contact`} className="full-width">
                        <Button type="ghost">
                            {formatMessage({id: 'faq.button.label'})}
                        </Button>
                    </Link>
                </Row>
            </div>
        </div>
    );
};

export default IAMMIAFAQBlock;
