import React from 'react';
import {Button, Row, Typography} from 'antd';
import VacancyPreview from '../vacancy-preview/VacancyPreview';
import {Link} from 'gatsby';
import styles from './VacanciesTeaser.module.less';
import {useIntl} from 'gatsby-plugin-intl';

const VacanciesTeaser = ({jobs}) => {
    const {Title} = Typography;
    const {locale, formatMessage} = useIntl();

    return (
        <div className={styles.section}>
            <div className="container text-center">
                <Title level={3} className={styles.title}>{formatMessage({id: 'current-jobs.title'})}</Title>
                <Row gutter={{xs: 40, sm: 40, md: 60, lg: 60}}>
                    {jobs.map((job) => <VacancyPreview key={job.id} post={job} />)}
                </Row>
                <Link to={`/${locale}/jobs`} className={styles.buttonContainer}>
                    <Button type="ghost">
                        {formatMessage({id: 'all-jobs.button'})}
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default VacanciesTeaser;
