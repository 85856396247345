import uxDesignAnimation from '../assets/animations/services/iam-mia-user-experience.json';
import uiDesignAnimation from '../assets/animations/services/iam-mia-user-interface-design.json';
import userResearchAnimation from '../assets/animations/services/iam-mia-user-research.json';

const useServicesImage = () => {
    const getAnimationSrc = (activeTab) => {
        switch (activeTab) {
            case 'ux-design':
                return uxDesignAnimation;
            case 'ui-design':
                return uiDesignAnimation;
            case 'user-research':
                return userResearchAnimation;
            default:
                return '';
        }
    };

    return {getAnimationSrc};
};

export default useServicesImage;