import React from 'react';
import {Col, Row} from 'antd';
import ServicesTabsMobile from './ServicesTabsMobile';
import ServicesTabsDesktop from './ServicesTabsDesktop';
import useGlobalState from '../../hooks/useGlobalState';
import styles from '../services-detailed/ServicesDetailed.module.less';
import useServicesImage from '../../hooks/useServicesImage';
import Lottie from 'react-lottie-segments';

const ServicesDetailed = () => {
    const globalState = useGlobalState();
    const activeTab = globalState.activeTab;
    const {getAnimationSrc} = useServicesImage();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: getAnimationSrc(activeTab),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <React.Fragment>
            <div className={styles.section}>
                <div className="container">
                    <Row>
                        <Col span={5} className="show-lg-up">
                            <Lottie
                                options={defaultOptions}
                            />
                        </Col>
                        <Col md={{span: 12}} lg={{span: 7}} className={styles.tabsBlock}>
                            <ServicesTabsDesktop />
                            <ServicesTabsMobile />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ServicesDetailed;
